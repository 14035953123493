<template>

	<div class="cont" id="p18">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="left">
				<div class="s01">
					<div class="c-item w100">
						<div class="title">등록일</div>
						<div class="content flex">
							<div class="cont-toggle">
								<div class="btn-toggle"
									:class="{ active: dateType == 'd'}"
									@click="setSearchDate('d')"
								>일간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'w'}"
									@click="setSearchDate('w')"
								>주간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'm'}"
									@click="setSearchDate('m')"
								>월간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'c'}"
									@click="setSearchDate('c')"
								>선택</div>
							</div>
							<div class="cont-date">
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="시작일"
										v-model="search.inquiryStartDate"
										@click="setSearchDate('c'); search.inquiryStartDate = ''; datePicker.start.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.start.view"
										v-model="search.inquiryStartDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 460px; border: 1px solid #bbb;"
										@change="datePicker.start.view = false"
									></v-date-picker>
								</div>
								<span>~</span>
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="종료일"
										v-model="search.inquiryEndDate"
										@click="setSearchDate('c'); search.inquiryEndDate = ''; datePicker.end.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.end.view"
										v-model="search.inquiryEndDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 660px; border: 1px solid #bbb;"
										@change="datePicker.end.view = false"
									></v-date-picker>
								</div>
							</div>
							<div class="notice">최대 3개월 이내에서 조회할 수 있습니다.</div>
						</div>
					</div>
					<div class="c-item">
						<div class="title">정산 상태</div>
						<div class="content">
							<select name="" id=""
								v-model="search.settlementStatus"
								@change="getData"
							>
								<option 
									v-for="(settlement, index) in settlementStatus"
									:key="index"
									:value="settlement.code"
								>{{ settlement.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">검색어</div>
						<div class="content">
							<input type="text" placeholder="대표자명, 가맹점명, 연락처를 입력하세요."
								v-model="search.keyword"
							>
						</div>
					</div>
				</div>
				<div class="s02">
					<a @click="getData"><div class="btn-search">조회</div></a>
					<a @click="clear"><div class="btn-reset">초기화</div></a>
					<a><div class="btn-excel">
						<vue-excel-xlsx
							:data="excel_items"
							:columns="columns"
							:filename="program.name"
							:sheetname="program.name"
						>
						엑셀 다운로드
						</vue-excel-xlsx>
					</div></a>
				</div>
			</div>
		</div>

		<div class="c02">
			<div class="table type07">
				<div class="text-right mb-10">
					<Pagination
						:options="search"
					/>
				</div>
				<div class="item-header">
					<div class="item">
						<div class="td" style="width: 5%;">NO</div>
						<div class="td" style="width: 10%;">정산일</div>
						<div class="td" style="width: 10%;">AGENT</div>
						<div class="td" style="width: 20%;">가맹점명</div>
						<div class="td" style="width: 5%;">결제건수</div>
						<div class="td" style="width: 10%;">결제금액</div>
						<div class="td" style="width: 10%;">정산금액</div>
						<div class="td" style="width: 10%;">수수료율</div>
						<div class="td" style="width: 10%;">상태</div>
						<div class="td" style="width: 10%;">상세내역</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item"
						v-for="(item, index) in items"
						:key="index"
					>
						<div class="td" style="width: 5%;">{{ item.rowNumber }}</div>
						<div class="td" style="width: 10%; justify-content: center !important;  margin-left: 0 !important;">{{ item.expPayDt | transDate }}</div>
						<div class="td" style="width: 10%; justify-content: center !important; "><span class="cc1">{{ item.agentInformation }} </span> </div>
						<div class="td" style="width: 20%; justify-content: start !important; padding-left: 10px !important;">{{ item.businessName }}</div>
						<div class="td" style="width: 5%">{{ item.totalPaymentCount | makeComma }}</div>
						<div class="td num" style="width: 10%">{{ item.totalPaymentAmount | makeComma }}</div>
						<div class="td num" style="width: 10%">{{ item.totalSettlementAmount | makeComma }}</div>
						<div class="td num" style="width: 10%">{{ item.feeRate | makeComma }}%</div>
						<div class="td" style="width: 10%"> 
							<div class="active"
								:class="item.status == 'Pause' ? 'type01' : (item.status == 'Normal' ? 'type02' : 'type03')"
							>{{ item.statusName }}</div> <!-- 속성 부여시 = class "active" -->
						</div>
						<div class="td" style="width: 10%"><div class="btn-show_info" @click="showPaymet({ expPayDt: item.expPayDt, pgMerchNo: item.pgMerchNo, pgTid: item.pgTid })">조회</div></div>
					</div>
				</div>
				<div
					v-if="items.length <= 0"
					class="no-data"
				>
					No Data
				</div>
				<div class="text-right mt-10">
					<Pagination
						:options="search"
					/>
				</div>
			</div>
		</div>

		<SettlementPopupItem
			:item="item"
			:showPopup="showPopup.item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		></SettlementPopupItem>
		
		<SettlementMerchantPaymentList
			v-if="showPopup.payment"
			:item="paymentItem"
			:user="user"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		></SettlementMerchantPaymentList>
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { date } from '@/resources/date/date'
	import { filter } from '@/resources/filter/filter'
	import { columns } from '@/resources/columns/columns'
	
	import SettlementPopupItem from '@/view/Settlement/Merchant/SettlementPopupItem'
	import SettlementMerchantPaymentList from '@/view/Settlement/Merchant/SettlementMerchantPaymentList'
	import Pagination from '@/components/Pagination'

	export default {
		name: 'PaymentsHistory'
		,props: ['user', 'codes']
		,components: { Pagination, SettlementPopupItem, SettlementMerchantPaymentList }
		,data: function(){
			return {
				program: {
					name: '가맹점 정산내역'
				}
				,agentSeq: this.user.basic.userSeq
				,items: [
				]
				,excel_items: [
				]
				,columns: columns.Merchant.settlement
				,item: {
				}
				,search_default: {
					inquiryStartDate: date.getSearchBaseDate()
					,inquiryEndDate: ''
					,keyword: ''
					,settlementStatus: ''
				}
				,search: {
					
				}
				,filter: filter
				,settlementStatus: this.codes.settlementStatus
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
				,showPopup: {
					item: false
					,payment: false
				}
				,today: date.getToday()
				,dateType: 'd'
				,paymentItem: {}
			}
		}
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlements/merchants'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						
						this.$set(this.search, 'total_page', result.data.content.totalPages)
						this.$set(this.search, 'total_count', result.data.content.totalElements)
						this.$set(this.search, 'list_cnt', result.data.content.size)
						
						await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,goDetail: function(pgMerchNo){
				this.$router.push({ to: 'PaymentsHistory', params: { payChnCate: pgMerchNo}})
			}
			,clear: function(){
				this.setSearchDate('c')
				this.search = {
					inquiryStartDate: date.getSearchBaseDate()
					,inquiryEndDate: ''
					,keyword: ''
					,settlementStatus: ''
				}
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlements/merchants/download'
						,data: {
							inquiryStartDate: this.search.inquiryStartDate
						}
						,authorize: true
					})

					if(result.success){
						this.excel_items = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,setSearchDate: async function(type){
				this.dateType = type
				if(type == 'd'){
					this.search.inquiryStartDate = this.today
					this.search.inquiryEndDate = this.today
				}else if(type == 'w'){
					let dates = date.getWeeklyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else if(type == 'm'){
					let dates = date.getMonthlyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else{
					this.search.inquiryStartDate = date.getSearchBaseDate()
					this.search.inquiryEndDate = date.getToday()
				}
				
				if(type != "c"){
					await this.getData()
				}
			}
			,showPaymet: function({ expPayDt, pgMerchNo, pgTid }){
				this.showPopup.payment = true
				this.paymentItem.expPayDt = expPayDt
				this.paymentItem.pgMerchNo = pgMerchNo
				this.paymentItem.pgTid = pgTid
				this.$emit('setOverlay', true)
			}
			,setOverlay: function(){
				this.showPopup.item = false
				this.showPopup.payment = false
				this.$emit('setOverlay', false)
			}
			,setNotify: function({ type, message}){
				this.$emit('setNotify', { type: type, message: message })
			}
		}
		,created: async function(){
			this.clear()
			await this.getData()
		}
		,watch: {
			'search.page': {
				handler: function(){
					this.getData()
				}
			}
			,'search.inquiryStartDate': {
				handler: function(call){
					this.search.inquiryStartDate = call.replaceAll('-', '')
				}
			}
			,'search.inquiryEndDate': {
				handler: function(call){
					this.search.inquiryEndDate = call.replaceAll('-', '')
				}
			}
		}
	}
</script>























